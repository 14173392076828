/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-1/4">
        <vx-card class="p-4">
          <div class="flex justify-center mb-6">
            <img src="https://rapidsimulations.com/simulations/6/images/Zeus-123.png" width="170" alt="" />
          </div>

          <h1 class="text-center mb-3">Default&nbsp;Open 6</h1>
          <h1 class="text-center mb-3">MyWi WEP 3</h1>
          <h1 class="text-center">PatsWiFi WEP 5</h1>
        </vx-card>
      </div>
      <div class="vx-col w-3/4">
        <vx-card>
          <vs-tabs>
            <vs-tab :label="tab.name" icon-pack="feather" :icon="tab.icon" v-for="(tab, tIndex) in properties" :key="tIndex">
              <div class="pt-4" style="min-height: 520px">
                <div class="pt-4" style="min-height: 520px">
                  <div v-for="section in tab.data" :key="section.id" class="p-5 border-solid mb-3 rounded">
                    <h3 class="font-bold break-all">{{ section.name }}</h3>
                    <vs-divider />
                    <div v-for="(field, field_i) in section.fields" :key="field_i" class="mt-4">
                      <div class="" v-if="field.type === 'html'" v-html="field.html"></div>
                      <div class="vx-row" v-if="field.type !== 'html'">
                        <div class="vx-col flex w-1/3">
                          <h3 class="break-all self-center">{{ field.name }}</h3>
                        </div>
                        <div class="vx-col w-2/3">
                          <v-select
                            v-if="field.type === 'dropdown'"
                            :options="field.options"
                            v-model="field.value"
                            :disabled="field.disabled"
                            class="w-full self-center"
                            placeholder="Select Option From Dropdown..."
                            size="large"
                          />
                          <vs-input
                            v-if="field.type === 'text'"
                            v-model="field.value"
                            :readonly="field.disabled"
                            class="inputx w-full self-center"
                          />
                          <div class="flex items-center" v-if="field.type === 'switch'">
                            <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
                            <h4 class="ml-2">{{ field.desc }}</h4>
                          </div>
                          <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
                          <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      properties: [
        {
          name: 'Wireless Setup',
          icon: 'icon-wifi',
          data: [
            {
              id: 0,
              name: 'Wireless Network Settings',
              fields: [
                {
                  name: 'Enable Wireless',
                  type: 'switch',
                  value: false,
                  answer: [true],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'Wireless Network Name (SSID)',
                  type: 'text',
                  value: '',
                  answer: ['Library'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'Wireless Channel',
                  type: 'dropdown',
                  value: '6',
                  answer: ['11'],
                  disabled: false,
                  options: ['1', '2', '3', '5', '6', '7', '8', '9', '10', '11', '12'],
                  score: null,
                },
                {
                  name: 'Enable SSID Broadcast',
                  type: 'switch',
                  value: true,
                  answer: [true],
                  disabled: false,
                  score: null,
                },
                {
                  name: '802.11g Only Mode',
                  type: 'switch',
                  value: false,
                  answer: [false],
                  disabled: false,
                  score: null,
                },
              ],
            },
            {
              id: 1,
              name: 'Wireless Security Mode',
              fields: [
                {
                  name: 'Security Mode',
                  type: 'dropdown',
                  value: '',
                  answer: ['Disable Wireless Security (not recommended)'],
                  disabled: false,
                  options: ['Disable Wireless Security (not recommended)', 'Enable WPA2 Security (enhanced)'],
                  score: null,
                },
              ],
            },
          ],
        },
        {
          name: 'Network Filter',
          icon: 'icon-globe',
          data: [
            {
              id: 0,
              name: 'Filter Setup',
              fields: [
                {
                  name: 'Enable MAC Filtering',
                  type: 'switch',
                  value: false,
                  answer: [false],
                  disabled: false,
                  score: null,
                },
                {
                  html: '<h3 class="mt-8 font-bold">Allow the following MAC Addresses</h3>',
                  type: 'html',
                },
                {
                  name: 'IP Address',
                  type: 'text',
                  value: '',
                  answer: ['', '', ''],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'IP Address',
                  type: 'text',
                  value: '',
                  answer: ['', '', ''],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'IP Address',
                  type: 'text',
                  value: '',
                  answer: ['', '', ''],
                  disabled: false,
                  score: null,
                },
              ],
            },
          ],
        },
        {
          name: 'Administrator Tools',
          icon: 'icon-settings',
          data: [
            {
              id: 0,
              name: 'Administrator Tools',
              fields: [
                {
                  name: 'Password',
                  type: 'text',
                  value: '',
                  answer: ['Secure$1'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'Confirm Password',
                  type: 'text',
                  value: '',
                  answer: ['Secure$1'],
                  disabled: false,
                  score: null,
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      this.properties.forEach((tab, tab_i) => {
        tab.data.forEach((category, category_i) => {
          if (category.fields) {
            category.fields.forEach((field, field_i) => {
              if (field.type !== 'html') {
                if (field.answer.includes(field.value)) {
                  this.properties[tab_i].data[category_i].fields[field_i].score = true;
                  totalScore++;
                } else {
                  this.properties[tab_i].data[category_i].fields[field_i].score = false;
                }
              }
            });
          }
        });
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Security+/Lgx201394311844069376.scss';

button.vs-tabs--btn {
  color: #ffffff;
}
</style>
